import React from 'react'
import Layout from '../../components/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import episodeArt from '../../images/song_art/ep9_art.png'

const Episode9 = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">family reunion</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container podcast is-fluid">
        <div className="columns name">
          <div className="column">
            <h1 className="is-size-4">Episode 9: Wake Up!</h1>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <img src={episodeArt} className="episode-art" alt="major industrial activity re-greening an alien planet" />
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/8362217/height/50/theme/legacy/thumbnail/no/preload/no/direction/backward/" height="50" width="100%" scrolling="no"></iframe>
          </div>
          <div className="column is-two-thirds transcript">
            <p>
              Welcome back to Planet and Sky, a cosmic love story, for our ninth and final episode. In our previous chapter, Planet reeled from the  loss of his beloved Sky. Though he knew she would never return, he took some consolation in being able to store his memories of her in a strange clay that lay just beneath his outer crust.
            </p>

            <p>
              If you recall, during the beginning of our story, scientists had encountered this very memory device during their exploration of what had appeared to be a dead planet. How did they react to the unleashing of this epic story and whatever became of the scientists who had fallen under its spell deep in the caverns within the planet? Let’s descend one final time to the barren planet, with its secret well of memories, one last time.
            </p>

            <p>
              Back at the base station, the data stream had finally come to an end. It would take some time for the complete story to be put together, but it was clear now that the explorers and the rescue team had become unintended participants in a complex narrative bound up in the memory clays. The data, fully analyzed, would reveal a rich history of the planet and its former biosphere. A new expedition was dispatched to recover the five unresponsive crewmembers and this time met with no misadventures. All were found unharmed, still in their suits and helmeted, though each had no memory of anything associated with their apparent transformations. There was no indication that the environment had ever been altered. The perceived environmental effects transmitted by the explorers had been just a part of their story.
            </p>

            <p>
              Remarkably, the astrobiologist and the planetologist had been transformed in a completely new way by their experiences. In effectively an instant, they’d fallen deeply in love, even though they’d shown no significant attraction to one another prior to the mission. They were now inseparable, and acted as if they’d always been a married couple. They had no direct recollection of their temporary incarnations as the personifications of the planet and its atmosphere, but found the analysis of the data stream highly entertaining and were in awe of the part they had played in its transmittance.
            </p>

            <p>
              The scientists left monitoring equipment and returned to the home world. The story of Planet and Sky became an immediate sensation. The five crewpersons who had channeled it became overnight celebrities, but none more so than the woman and the man who had unleashed the data stream. They went on speaking tours around the globe and drew huge crowds and were loved wherever they went. Their story was so popular that a movement was unleashed to study the possibility of resurrecting the planet’s biosphere in some way. Eventually, this provided the resources and motivation for the grand project to follow.
            </p>

            <p>
              Back on the planet, the eons of cold, ice, and punishing radiation had left a desolate landscape with no living survivors upon the surface. Even the microbes who had succeeded in extending their lives by burrowing deep beneath it were mostly baked and starved now, broken up into their constituent elements. It was a barren, empty, and by all appearances lifeless world.
            </p>

            <p>
              Deep beneath the surface, however, there were some who had been spared the widespread devastation above. When the ancient comet from far beyond the limits of the star’s gravitational reach had crashed into the planet, some parts of the great mass of rock and ice had become buried deeply underground and had remained there for billions of years.
            </p>

            <p>
              They had remained in hibernation until a series of changes began to disturb their seemingly endless sleep. The scientists had returned and put their grand plan into motion. Their first actions were aimed at re-invigorating the activity of the planet’s mantle by generating heat deep beneath the crust. At regular intervals across the surface of the orb, holes had been drilled to considerable depth, and extremely powerful explosives had been detonated. The resultant heat generated new pockets of molten rock, and great waves of energy poured through the depths, creating swarms of powerful subsurface earthquakes.
            </p>

            <p>
              The first traveler gradually came to experience the change in its surroundings and its thoughts emanated outward.
            </p>

            <p>
              “Do you feel it?” it asked. “A warming in the ground? Something’s happening. Vibrations all around.”
            </p>

            <p>
              Far away, a familiar companion stirred… and complained. The second traveler had awakened.
            </p>

            <p>
              “What life of misery - such a sleep of terrors?,” it whined. “What new torment coming to realize our fears?”
            </p>

            <p>
              Deeper still, the third traveler slept, but gradually came to consciousness. It felt no great excitement, and certainly no concern.
            </p>

            <p>
              “I like the peace and quiet, a lovely place to sleep,” it said. “I’ll get up in a moment, is there anything to eat?”
            </p>

            <p>
              The scientists had a bold agenda. Giant orbital mirrors were being assembled to reflect sunlight onto the poles, where the resulting heat would melt the frozen tundra and boost the thickness of the atmosphere. Dark dust was being sprinkled on the lighter surfaces to lessen the reflectivity of the surface and allow the planet to soak in more heat. Various microbial lifeforms selected for their ability to thrive under extreme conditions were also introduced. It was a slow process, but in a matter of only a few years, the temperature and thickness of the air had noticeably increased. The time had arrived to begin the process of repopulating the planet.
            </p>

            <p>
              The goal was nothing less than to re-establish a stable and enduring biosphere. A catalog of attributes for the world’s wide variety of life had been compiled from the residual data found in the extensive charged clay beds decoded and interpreted by the discovery team. Extinct native creatures would be redeveloped through combinations of stimulating the rapid evolution of extant life and genetic engineering. The progression of life from single celled organisms through multicellular life, and eventual highly complex organisms would have to be carefully managed and would require many decades of effort.
            </p>

            <p>
              Planet stirred and awareness dawned slowly. He had been in a deep, dreamless sleep for a very long time. His first thought was for Sky and his grief fell hard upon him once more. Why had he been awakened to again face reality without her? But then… he observed the growing warmth. His mantle was reverberating with activity he hadn’t felt since before that terrible occasion so long ago when she sang to him the mournful news of their impending separation. He dared not think the thought that intruded now. He was, somehow, returning to life. Could it be possible she could return as well?
            </p>

            <p>
              “Long I lay in endless sleep,” he sang. “My time, my love had passed.. Now what stirs me, brings me warmth, is she here again at last?”
            </p>

            <p>
              In the lowlands and deep basins the atmosphere thickened. Gradually it rose to envelop the higher regions as well. Patterns of activity shifted and danced. A delicate voice arose, a familiar sound from long, long ago, and a being that somehow remembered a previous incarnation. Sky had returned.
            </p>

            <p>
              She sang, “I was drained and long dispersed, now buoyed by life anew, accelerated bounty blooms, does my lover feel this too?”
            </p>

            <p>
              The changes in the microbial life were accelerating. New collections of cells were expanding and differentiating, developing new capabilities. Independent movement, hunger and reproduction, powerful sensitivity to light and heat, sexual reproduction!
            </p>

            <p>
              The first traveler cried out in celebration, “Our promised world is here now… just as I forecast, time to start evolving, this time make it last!”
            </p>

            <p>
              The season of rebirth was upon them. The second traveler felt the pain to come as it had always felt it so strongly before, but its dread was muted by the excitement of a new burst of creativity
            </p>

            <p>
              “Seems we face a new round,” it sang, “new anguish comes again, the beauty and the tragedy, I’ll represent the pain.”
            </p>

            <p>
              The third traveler was pleased. They had endured all the ups and downs of their long history and assumed they would do so again. For now, a season of growth and well being had washed over them and it relished the opportunity to thrive again.
            </p>

            <p>
              “Cell walls are expanding,” it cried joyously, “arms and legs and tail, duty calls to eat and grow. Maybe I’ll become a whale!”
            </p>

            <p>
              The scientists increased their efforts to add more and more material to drive the powerful greenhouse effect which was accelerating the warming of the skies. Distant asteroids rich in ice and frozen ammonia were transported inward and dropped into the planet. The vast majority of the material would be evaporated in the process of heating while descending through the thickening atmosphere.
            </p>

            <p>
              As for plant life, the team went far beyond just microscopic plants and simple lichens now. Grasses multiplied, then shrubbery and actual trees. Green algae and phytoplankton filled the growing seas and generated breathable quantities of oxygen, boosting the animal life which now began swarming over the land. The engineers found it possible to create more and more complex beings to populate the emerging ecosystems.
            </p>

            <p>
              Sky wept with joy and filled the lowlands and ocean basins once again with her tears. Her beautiful storms returned with abundance.  She carved new rivers in the uplands created by Planet’s enthusiastic movements and eruptions. Their world was once again fully alive.
            </p>

            <p>
              Planet sang out in joyful abandon, “No more alone I live now, above my Sky abides.”
            </p>

            <p>
              …and Sky responded gleefully, “I dance and sing in happiness, below my Planet sighs.”
            </p>

            <p>
              Together they expressed their reverence for yet another opportunity to love one another and all the beings within their expanding fold, “A gift, of joy and grace. With love, embrace… new life!”
            </p>

            <p>
              Back on the home world, the woman caught a fresh updraft, beat her artificial wings and caught up with her similarly outfitted husband. With some of the resources they’d been gifted by a loving public, they’d invested in new technologies to allow birdlike human flight and were trying out the latest model of their high tech human powered flightsuits.
            </p>

            <p>
              Gliding together in the sun-warmed air above a gorgeous blue lake, they banked together to explore a neighboring valley. Just another wondrous moment in the beautiful life of a woman and a man once known as Planet and Sky. Life was good.
            </p>

            <p>
              Decades had passed, the planet had warmed to a comfortable temperature and the air had thickened to the point that a protective suit was no longer needed and one could breathe easily without equipment of any kind. The woman and the man had settled in a remote and lush valley and were out on an aerial adventure in their latest model of flightsuit designed specifically for the strange new world they now called home.
            </p>

            <p>
              Far below, on a rock near a mountain lake, a pair of ape-like creatures sat in the morning light gazing out upon the beautiful scene before them. The male stroked the fur of his mate beside him and glanced upward to see the two fliers gliding across the cloudless dome of the sky. Planet turned to Sky and his eyes twinkled with delight. “Should we take a look from above, my dear?” he asked.
            </p>

            <p>
              “No, my sweet,” she replied with a laugh, “we’re already there.”
            </p>

            <p>
              We hope you enjoyed the story of Planet and Sky in these nine chapters, as well as the original music for each episode. If you would like to experience the music in its entirety I invite you to visit planetandsky.com to purchase the soundtrack as a CD. You will also find the full lyrics of the songs, transcripts of these podcasts as well as information regarding the artists responsible for its creation.
            </p>

            <p>
              I’d like to thank the musical and literary creator of Planet and Sky as well as bassist and voice of Planet, Joel McKinnon, and Melissa Olsen, keyboardist and voice of Sky, and Byron Bellamy, our Chief Scientist and sound engineer for all of the music. Other contributing artists include Darryl Dardenne on drums, Lance Tabor on guitars, and special guest Rebecca Rust who stepped in to play the cello on “Planet My Love.”
            </p>

            <p>
              Many others contributed to the success of this project and you can read all about the making of Planet and Sky at planetandsky.com. Please be sure to stop over and pay a visit.
            </p>

            <p>
              Also, if you enjoyed the story, please write a short review on iTunes. Joel would love to hear your comments and thoughts on the album and the podcast.
            </p>
          </div>
        </div>
      </div>
  </section>
  </Layout>
)

export default Episode9
